.block {
  display: block;
  width: 100px;
}

.landing {
  &-text {
    font-family: $font-primary;
    font-size: $font-primary-size;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 5px;
    &__title {
      font-family: $font-primary;
      font-size: $font-primary-size;
      font-weight: normal;
      text-transform: lowercase;
      margin-top: 5px;
      margin-bottom: 1px;
    }
  }
  &-link {
    font-family: $font-primary;
    font-size: $font-primary-size;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: $color-primary;
    line-height: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      font-family: $font-primary-hover;
      font-style: italic;
      color: $color-secondary;
    }
  }
  &-list {
    padding-left: 20px;
    margin-top: 0;
    list-style: circle;
  }
}
