//colors
$color-primary: black;
$color-secondary: rgb(45, 119, 119);

//fonts
$font-primary: 'CourierNew';
$font-primary-hover: 'CourierNew-Italic';
$font-secondary: 'CourierNew-Bold';
$font-secondary-hover: 'CourierNew-BoldItalic';

//font size
$font-primary-size: 90%;
$font-secondary-size: 100%;

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 75%;
    color: $color-secondary;
}