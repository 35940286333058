@font-face {
  font-family: 'CourierNew';
  src: url('CourierNewPSMT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CourierNew-Italic';
  src: url('CourierNewPS-ItalicMT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CourierNew-Bold';
  src: url('CourierNewPS-BoldMT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CourierNew-BoldItalic';
  src: url('CourierNewPS-BoldItalicMT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
