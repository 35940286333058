.footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;

  &-text {
    color: $color-primary;
    text-decoration: none;
    margin: 10px;
    font-size: 60%;
  }
  &-position {
    justify-content: flex-end;
    align-items: flex-end;
  }
}
