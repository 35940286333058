@media all and (min-width: 800px) {
  .works {
    width: 1020px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &-img-small {
      margin-top: 10px;
      margin-left: 10px;
      max-width: 500px;
      max-height: 500px;
    }
    &-img-med {
      margin-top: 10px;
      margin-left: 10px;
      max-height: 500px;
    }
    &-img-large {
      margin-top: 10px;
      margin-left: 10px;
      max-width: 1010px;
      max-height: 1010px;
    }
  }
}
  
@media all and (max-width: 800px) {
  .works {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-img-small {
      margin-top: 10px;
      max-width: 100%;
    }
    &-img-med {
      margin-top: 10px;
      max-width: 100%;
    }
    &-img-large {
      margin-top: 10px;
      max-width: 100%;
    }
  }
}
