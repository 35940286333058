@media all and (min-width: 800px) {
  .bio {
    width: 1120px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &-img {
      margin-left: 10px;
      max-height: 500px;
    }
  }
}

@media all and (max-width: 800px) {
  .bio {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-img {
      margin-left: 10px;
      margin-top: 20px;
      max-width: 100%;
    }
  }
}
