.body {
  margin-top: 8px;
  margin-left: 13px;
  margin-right: 13px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.arrow-icon {
  font-size: 120%;
  color: $color-primary;
  position: relative;

  &:hover {
    color: $color-secondary;
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

.text {
  margin-top: 0;
  margin-bottom: 2px;
  font-family: $font-primary;
  font-size: $font-primary-size;
  font-weight: normal;
  line-height: 22px;
  &-title {
    font-family: $font-secondary;
    font-size: $font-secondary-size;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  &-italic {
    font-family: $font-primary-hover;
    font-size: $font-primary-size;
    font-weight: normal;
    margin-bottom: 2px;
  }
}

.link {
  font-family: $font-primary;
  font-size: $font-primary-size;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  color: $color-primary;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 5px;
  &:hover {
    font-family: $font-primary-hover;
    font-style: italic;
    color: $color-secondary;
  }
  &-separate {
    font-family: $font-primary;
    font-size: $font-primary-size;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: $color-primary;
    line-height: 22px;
    margin-top: 35px;
    margin-bottom: 5px;
    display: inline-block;
    &:hover {
      font-family: $font-primary-hover;
      font-style: italic;
      color: $color-secondary;
    }
  }
}

.signature {
  margin-top: 20px;
  text-decoration: underline;
  text-align: end;
}

.image {
  margin-top: 15px;
  display: block;
}

.video {
  padding-left: 50px;
  width: 800px;
  height: 525px;
  border: 0;
  padding: 0;
  margin-top: 15px;
}

.initial-text {
  margin-top: 0;
  margin-bottom: 2px;
  font-family: $font-primary;
  font-size: $font-primary-size;
  font-weight: normal;
  line-height: 22px;
  margin-left: 25px;
}

.names-list {
  font-family: $font-primary;
  font-size: $font-primary-size;
  font-weight: normal;
  line-height: 22px;
  list-style: none;
  padding-left: 0;
  margin-left: 25px;
  margin-top: 0;
  margin-bottom: 5px;
  &__item {
    display: inline;
    margin-left: 5px;
    &-first {
      display: inline;
      margin-left: 0;
    }
  }
}

.quote {
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
}
