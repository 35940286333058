@media all and (min-width: 800px) {
    .logo {
        width: 1120px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &-small {
            max-width: 200px;
            max-height: 200px;
            margin-top: 10px;
            margin-left: 35px;
        }
        &-large {
            max-width: 100px;
            max-height: 100px;
            margin-top: 10px;
            margin-left: 35px;
        }
    }
}

@media all and (max-width: 800px) {
    .logo {
        width: 360px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        margin-left: 10px;
        &-small {
            max-width: 200px;
            max-height: 200px;
            margin-top: 10px;
            margin-left: 10px;
        }
        &-large {
            max-width: 100px;
            max-height: 100px;
            margin-top: 10px;
            margin-left: 10px;
        }
    }
}